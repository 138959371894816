import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui';
// import Vue from 'vue'
import router from '../router'
import store from '../store'
// import i18n from '../lang'

axios.defaults.timeout = 15000;                        //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';        //配置请求头
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;   //配置接口地址

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    //在发送请求之前做某件事
    if (config.method === 'post') {
        config.data = qs.stringify(config.data);
    }

    config.headers.common = {
        'Access-Control-Allow-Origin': '*'
    };

    let token = localStorage.getItem('access_token');
    // console.log('11:'+token);
    if (token) {
        config.headers.Authorization = token
    }

    // if(token){
    //   config.params = {'token':token}
    // }

    return config;
}, (error) => {
    console.log('错误的传参')
    return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
    // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token。你可以根据你的业务需求自己编写更新 token 的逻辑
    var token = res.headers.authorization;
    if (token) {
        // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
        store.commit('refreshToken', token)
    }
    //对响应数据做些事
    if (!res.data.success) {
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    console.log('网络异常');

    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            // 如果响应中的 http code 为 400，那么就弹出一条错误提示给用户
            case 400:
                // error.message = '错误请求'
                // error.message = error.response.data.message;
                Message.error(error.response.data.message);
                break;
            // 如果响应中的 http code 为 401，那么则此用户可能 token 失效了之类的，我会触发 logout 方法，清除本地的数据并将用户重定向至登录页面
            case 401:
                error.message = '未授权，请重新登录';
                // error.message = i18n.t('http.unauthorized'); //未授权，请重新登录
                store.commit('setLogout');
                router.push('/login');
                break;
            case 403:
                error.message = '拒绝访问'
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                // window.location.href = "/NotFound"
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:
                error.message = '请求超时'
                break;
            case 420:
                error.message = '未授权，请重新登录';
                store.commit('setLogout');
                router.push('/login');
                break;
            case 422:
                var messages = error.response.data.message;
                for (let key in messages) {
                    for (let i = 0; i < messages[key].length; i++) {
                        Message.error(messages[key][i])
                    }
                }
                break;
            case 500:
                error.message = '服务器端出错'
                break;
            case 501:
                error.message = '网络未实现'
                break;
            case 502:
                error.message = '网络错误'
                break;
            case 503:
                error.message = '服务不可用'
                break;
            case 504:
                error.message = '网络超时'
                break;
            case 505:
                error.message = 'http版本不支持该请求'
                break;
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            Message.error('服务器响应超时，请刷新当前页')
        }
        error.message('连接服务器失败')
    }

    // Message.error(error.message)
    /***** 处理结束 *****/

    return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost (url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
////返回一个Promise(发送get请求)
export function fetchGet (url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
////返回一个Promise(发送delete请求)
export function fetchDel (url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url,{ params: params })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export default {
    fetchPost,
    fetchGet,
    fetchDel
}
