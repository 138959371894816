export default {
    login: "/api/v1/login",   //登陆
    logout: "/api/v1/logout", //退出登陆
    register:'/api/v1/users', //用户注册
    verification:'/api/v1/order/verification',  //验证码
    user_changepass: '/api/v1/changePass',  //修改密码
    user_info: '/api/v1/me',   //获取用户信息

    // shopify店铺
    shop_list:'/api/v1/shop/list',
    shop_create:'/api/v1/shop',
    //收款方式
    payment_list:'/api/v1/payment/list',
    payment_create:'/api/v1/payment',

    search:'/api/v1/search',
    // 首页热销产品数据
    slideshow_data:'/api/v1/index',
    // 联系我们信息提交
    contact_us:'/api/v1/message',
    // 产品页面导航栏数据
    categories:'/api/v1/categories',
    //获取产品数据
    pd_list:'/api/v1/products',


    // order_info:'/api/v1/order/info',
    // upload_file:'/api/v1/upload/file',
    // delete_file:'/api/v1/delete/file',//删除图片

}
