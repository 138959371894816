import Vue from 'vue'
import Router from 'vue-router'

/**
 * 重写路由的push方法;解决重复点击同一个路由报错问题
 */
const routerPush = Router.prototype.push;
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
};

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index'  //首页默认跳转到HomeIndex
    },
    {
      path: '/home',
      name: 'home',
      redirect: '/index',
      component: () => import('@/views/public/PublicHome'),
      children: [
        {
          path: '/index',
          name: 'Index',
          component: () => import('@/views/index/HomeIndex'),
        },
       
        {
          path: '/products',
          name: 'Products',
          component: () => import('@/views/product/ProductList'),
        },
        {
          path: '/choose-us',
          name: 'Choose',
          component: () => import('@/views/choose/ChooseUs'),
        },
        {
          path: '/work',
          name: 'Work',
          component: () => import('@/views/work/HowWork'),
        },
        {
          path: '/about',
          name: 'About',
          component: () => import('@/views/about/AboutUs'),
        },
        {
          path: '/contact-us',
          name: 'Contact',
          component: () => import('@/views/contact/ContactUs'),
        },
        {
          path: '/about-us',
          name: 'AboutUs',
          component: () => import('@/views/terms/AboutUs'),
        },
        {
          path: '/terms-services',
          name: 'TermsServices',
          component: () => import('@/views/terms/TermsServices'),
        },
        {
          path: '/privacy-policy',
          name: 'Privacy',
          component: () => import('@/views/terms/PrivacyPolicy'),
        },
        {
          path: '/dmca',
          name: 'Dmca',
          component: () => import('@/views/terms/Dmca'),
        },
        {
          path: '/conditions-b',
          name: 'Conditions Businesses',
          component: () => import('@/views/terms/ConditionsB'),
        },
        {
          path: '/terms-cc',
          name: 'Terms & Conditions Consumers',
          component: () => import('@/views/terms/TermsCC'),
        },
        {
          path: '/refunds-policy',
          name: 'Refunds Policy',
          component: () => import('@/views/terms/RefundsPolicy'),
        },
      ]
    },


  ]
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
/*router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/register') {
    next();
  } else {
    let token = localStorage.getItem('access_token');
    // console.log(token)
    if (token === null || token === '') {
      // 修改了跳转到主页
      next('/login');
    } else {
      next();
    }
  }
});*/
export default router
