export default {
    changeCollapse(state) {
        state.isCollapse = !state.isCollapse;
        try {
            sessionStorage.isCollapse = state.isCollapse;
            // eslint-disable-next-line no-empty
            } catch (e) {
        }
    },
    minCollapse(state) {
        state.isCollapse = true;
        try {
            sessionStorage.isCollapse = state.isCollapse;
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    },
    setLogin(state,token){
        state.access_token = token;
        try {
            localStorage.setItem('access_token',token)
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    },
    refreshToken(state,token){
        console.log(568)
        state.access_token = token;
        try {
            localStorage.setItem('access_token',token)
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    },
    setLogout(state){
        state.access_token='';
        state.user_info={};
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_info');
    },
    setUserInfo(state,info){
        state.user_info=info;
        localStorage.setItem('user_info',JSON.stringify(info))
    },
    setIntNo(state,int_no){
        state.int_no=int_no;
        // localStorage.setItem('user_info',JSON.stringify(info))
        sessionStorage.setItem('int_no',int_no)
    }
}
