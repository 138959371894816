let defaultCollapse = false;
let defaultToken = '';
let default_user_info={};
let default_int_no='';
try {
    if (sessionStorage.isCollapse) {
        if (sessionStorage.isCollapse === 'true') {
            defaultCollapse = true;
        } else {
            defaultCollapse = false;
        }
    }

    if (localStorage.getItem('access_token')) {
        defaultToken=localStorage.getItem('access_token');

    }

    if (localStorage.getItem('user_info')) {
        default_user_info=JSON.parse(localStorage.getItem('user_info'));
    }

// eslint-disable-next-line no-empty
} catch (e) {
}

try {
    if (sessionStorage.getItem('int_no')) {
        default_int_no=sessionStorage.getItem('int_no')
    }

// eslint-disable-next-line no-empty
} catch (e) {
}


export default {
    isCollapse: defaultCollapse,
    access_token: defaultToken,
    user_info:default_user_info,
    int_no:default_int_no, //邀请码
}
