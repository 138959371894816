import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueAwesomeSwiper from 'vue-awesome-swiper'

// 移动端300毫秒点击延迟解决方案
// 项目运行 npm install fastclick  --save
import fastClick from 'fastclick'
// 使用fastClick
fastClick.attach(document.body)

// 统一移动端css样式文件
import '@/assets/css/reset.css'

// import '@/assets/css/main.css'
// 像素边框解决方案文件
import '@/assets/css/border.css'

////引入element,全引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI,{ locale });

// 引入swiper
// import 'swiper/dist/css/swiper.css'
// Vue.use(VueAwesomeSwiper);

//引入两个文件
import api from './api/api'
import http from './api/http'
//挂载到VUE实例，以便全局使用
Vue.prototype.api = api;
Vue.prototype.http = http;

//时间处理js
import moment from 'moment'//导入文件
import 'moment-timezone';
Vue.prototype.$moment = moment

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
